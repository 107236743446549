<template>
<div class="setting_main">
  <div class="Privacy" @click="privacy">用户隐私协议</div>
  <div class="logout" @click="show = true">退出登录</div>
  <van-popup v-model="show" position="bottom" :overlay="false" :style="{ height: '15%' }">
    <div class="popup">
      <span @click="logout">退出登录</span>
      <span @click="show = false">取消</span>
    </div>
  </van-popup>
</div>
</template>

<script>
export default {
  name: "setting",
  data(){
    return{
      show: false
    }
  },
  methods:{
    privacy(){
      const url = 'https://cdn.51lxj.cn/documents/s6PofF8VNhtsEoeh8S.pdf';
      this.$router.push({name: 'openPdf',query:{url:url}})
    },
   async logout(){
     await this.$store.dispatch('user/resetToken')
     await this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped lang="scss">
.setting_main{
    .Privacy{
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      margin: 12px 0;
      text-align: center;
      line-height: 44px;
    }
    .logout{
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      text-align: center;
      line-height: 44px;
    }
    .popup{
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      span{
        width: 100%;
        display: block;
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
        &:first-child{
          color: #D9001B;
        }
        &:last-child{
          color: #333333;
        }
      }
    }
}
</style>
